import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../Context/Context";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Stack,
} from "@mui/material";
import CampaignCard from "../Components/Campnaigns/CampaignCard";
import CampaignModal from "../Components/Campnaigns/CampaignModal";

const Campaigns = () => {
  const {
    getCampaigns,
    campaigns,
    loadingCampaigns,
    editCampaign,
    createNewCampaign,
    deleteCampaign,
  } = useContext(AppContext);

  useEffect(() => {
    getCampaigns();
  }, []);

  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [modifyParams, setModifyParams] = useState([]);
  const [creatingCampaign, setCreatingCampaign] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);

  const onCreateNewCampaign = () => {
    setSelectedCampaign({
      name: "",
      requestBody: "",
      requestUrl: "",
      requestConfig: "{}",
      requestMethod: "GET",
      modifyParams: [],
      requestDelay: "",
    });
    setCreatingCampaign(true);
  };

  const onSubmit = (data) => {
    if (creatingCampaign) {
      createNewCampaign({ ...data, modifyParams });
    } else {
      editCampaign(selectedCampaign.id, { ...data, modifyParams });
    }

    setSelectedCampaign(null);
    setCreatingCampaign(false);
    setModifyParams([]);
  };

  if (loadingCampaigns) return <LinearProgress color="secondary" />;

  return (
    <Stack
      sx={{
        gap: "35px",
        padding: { xs: "15px", md: "20px 30px" },
      }}
    >
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={onCreateNewCampaign}
      >
        Create new Campaign
      </Button>
      {campaigns?.map((campaign) => (
        <CampaignCard
          key={campaign.id}
          name={campaign.name}
          requestMethod={campaign.requestMethod}
          requestDelay={campaign.requestDelay}
          requestUrl={campaign.requestUrl}
          requestBody={campaign.requestBody}
          requestConfig={campaign.requestConfig}
          modifyParams={campaign.modifyParams}
          campaignId={campaign.id}
          onCopy={() => {
            const newCampaign = campaign;
            delete newCampaign.id;
            setSelectedCampaign(newCampaign);
            setCreatingCampaign(true);
          }}
          onEdit={() => {
            setModifyParams(JSON.parse(campaign.modifyParams));
            setSelectedCampaign(campaign);
          }}
          onDelete={() => {
            setCampaignToDelete(campaign);
          }}
        />
      ))}

      <CampaignModal
        title={creatingCampaign ? "Create new campaign" : "Edit campaign"}
        isOpen={Boolean(selectedCampaign)}
        modifyParams={modifyParams}
        setModifyParams={setModifyParams}
        onClose={() => {
          setSelectedCampaign(null);
          setCreatingCampaign(false);
        }}
        defaultState={selectedCampaign}
        onSubmit={onSubmit}
      />
      <Dialog
        open={Boolean(campaignToDelete)}
        onClose={() => setCampaignToDelete(null)}
      >
        <DialogTitle>Want to delete campaign ?</DialogTitle>
        <DialogContent>
          <DialogContentText>{campaignToDelete?.name}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCampaignToDelete(null)} autoFocus>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              deleteCampaign(campaignToDelete?.id);
              setCampaignToDelete(null);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default Campaigns;
