import React, { useContext, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "./Context/Context";
import Roles from "./constants/Roles";
import { Locations } from "./constants/getAllowedLocations";

const NavigateToDefault = () => {
  const { admin, notAuthorized } = useContext(AppContext);

  const defaultLocation = useMemo(() => {
    if (!admin && notAuthorized) return;

    const roles = admin?.roles ?? [];

    if (roles.includes(Roles.superAdmin)) return Locations.campaigns;
    if (roles.includes(Roles.admin)) return Locations.campaigns;
    if (roles.includes(Roles.manager)) return Locations.campaigns;
    if (roles.includes(Roles.moderator)) return Locations.campaigns;
  }, [admin]);
  return <Navigate to={defaultLocation} />;
};

export default NavigateToDefault;
