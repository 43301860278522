import Roles from "./Roles";

const ACCESSES = {
  createCampaigns: [Roles.superAdmin, Roles.admin, Roles.manager],
  editCampaign: [Roles.superAdmin, Roles.admin, Roles.manager],
  deleteCampaign: [Roles.superAdmin, Roles.admin, Roles.manager],
  listCampaigns: [Roles.superAdmin, Roles.admin, Roles.manager],
};

export default ACCESSES;
