import React from "react";
import { Box } from "@mui/material";
import Navigation from "./Navigation";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        padding: "75px 0 0",
        backgroundColor: "bg.main",
      }}
    >
      <Navigation />
      <Outlet />
    </Box>
  );
};

export default Layout;
