import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import ACCESSES from "./constants/Accesses";
import { Locations } from "./constants/getAllowedLocations";
import NavigateToDefault from "./NavigateToDefault";
import Layout from "./Components/Layout";
import Campaigns from "./pages/campaigns";

const RoutesContainer = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          path={Locations.campaigns}
          element={
            <ProtectedRoute allowedRoles={ACCESSES.listCampaigns}>
              <Campaigns />
            </ProtectedRoute>
          }
        />
        <Route path="/*" element={<NavigateToDefault />} />
      </Route>
    </Routes>
  );
};

export default RoutesContainer;
