import React, { useMemo, useContext } from "react";
import { AppContext } from "./Context/Context";
import NavigateToDefault from "./NavigateToDefault";

const ProtectedRoute = ({ allowedRoles = [], children }) => {
  const { admin } = useContext(AppContext);

  const allowed = useMemo(
    () =>
      allowedRoles.length === 0 ||
      admin?.roles?.some((role) => allowedRoles.includes(role)),
    [admin, allowedRoles]
  );

  if (!allowed)
    return (
      <>
        <NavigateToDefault />
      </>
    );

  return children;
};

export default ProtectedRoute;
