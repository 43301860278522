import Roles from "./Roles";

export const Locations = {
  campaigns: "/campaigns",
};

const ALL_LOCATIONS = [Locations.campaigns];

const getAllowedLocations = (roles = []) => {
  const rolesLocations = {
    [Roles.superAdmin]: [...ALL_LOCATIONS],
    [Roles.admin]: [...ALL_LOCATIONS],
    [Roles.manager]: [...ALL_LOCATIONS],
    [Roles.moderator]: [Locations.campaigns],
  };

  return rolesLocations[roles[0]];
};

export default getAllowedLocations;
