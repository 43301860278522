import React, { createContext, useEffect, useState } from "react";
import api from "../api";
import loginApi from "../api/loginApi";

export const AppContext = createContext({
  admin: null,
  notAuthorized: false,
  appLoading: true,
  campaigns: [],
  getCampaigns: () => {},
  logout: () => {},
  loadingCampaigns: true,
  createNewCampaign: () => {},
  editCampaign: () => {},
  deleteCampaign: () => {},
});

const Context = ({ children }) => {
  /*************************
   *  ADMIN
   *************************/

  const [appLoading, setAppLoading] = useState(false);
  const [admin, setAdmin] = useState(null);
  const [notAuthorized, setNotAuthorized] = useState(false);

  const DEVELOPMENT = process.env.NODE_ENV === "development";

  const getAdmin = () => {
    setAppLoading(true);
    loginApi
      .getAdmin()
      .then((me) => setAdmin(me))
      .catch(() => {
        setNotAuthorized(true);
        setAdmin(null);
      })
      .finally(() => setAppLoading(false));
  };

  useEffect(() => {
    getAdmin();
  }, []);

  useEffect(() => {
    const redirectBaseLink = DEVELOPMENT
      ? "http://localhost:5003"
      : "https://admin.axioma.monster";

    if (!admin && notAuthorized) {
      window.location = `${redirectBaseLink}/login?returnTo=$$$${process.env.REACT_APP_URL}$$$`;
    }
  }, [admin, notAuthorized]);

  const logout = () => {
    loginApi.logout().then(() => {
      setAdmin(null);
      setNotAuthorized(true);
    });
  };

  /*************************
   *  CAMPAIGNS
   *************************/

  const [campaigns, setCampaigns] = useState([]);
  const [loadingCampaigns, setLoadingCampaigns] = useState(false);

  const getCampaigns = () => {
    setLoadingCampaigns(true);
    api.campaigns
      .getAllCampaigns()
      .then((items) => setCampaigns(items))
      .catch((e) => console.log(e))
      .finally(() => setLoadingCampaigns(false));
  };
  const createNewCampaign = (data) => {
    api.campaigns
      .createCampaign(data)
      .then(() => getCampaigns())
      .catch((e) => console.log(e));
  };
  const deleteCampaign = (id) => {
    api.campaigns
      .deleteCampaign(id)
      .then(() => getCampaigns())
      .catch((e) => console.log(e));
  };
  const editCampaign = (id, data) => {
    api.campaigns
      .editCampaign(id, data)
      .then(() => getCampaigns())
      .catch((e) => console.log(e));
  };
  return (
    <AppContext.Provider
      value={{
        appLoading,
        admin,
        notAuthorized,
        campaigns,
        logout,
        loadingCampaigns,
        getCampaigns,
        createNewCampaign,
        deleteCampaign,
        editCampaign,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default Context;
