const modifiers = {
  uaPhone: {
    name: "UA phone",
    param: "phone",
    modifier: `phone = phone[0] === '+' ? phone.slice(1) : phone;
const sub = phone.substring(0, 3);
phone = sub === '380' ? phone : '380' + phone;
const sub2 = phone.substring(0, 4);
phone = sub2 === '3800' ? '380' + phone.slice(4) : phone;

return phone;`,
  },
  offers: {
    name: "Postback offers",
    param: "offer_id",
    modifier: `switch (offer_id) {
        case '1': return 'pkmStatus'
        case '7':  return 'jokStatus'
        case '54':  return 'favStatus'
        case '56':  return 'mostbetStatus'
        case '57':  return 'onewinStatus'
        default: return 'takoiSytuaciiNeBude'
    }`,
  },
};

export default modifiers;
