import React, { useState } from "react";
import { Avatar, Menu, MenuItem } from "@mui/material";
import stringToColor from "../../utils/stringToColor";

function stringAvatar(name = "") {
  return {
    sx: {
      backgroundColor: stringToColor(name),
      ml: "auto",
      cursor: "pointer",
    },
    children: `${name[0]}`,
  };
}

const AvatarComponent = ({ name, logout }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Avatar {...stringAvatar(name)} onClick={handleClick} />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default AvatarComponent;
