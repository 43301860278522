import axios from "axios";

const DEVELOPMENT = process.env.NODE_ENV === "development";

const API_URL = DEVELOPMENT
  ? "http://localhost:5003/api"
  : "https://admin.axioma.monster/api";

const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

const loginApi = {
  async getAdmin() {
    const { data } = await instance.get("/admins/me");

    return data;
  },

  async logout() {
    const { data } = await instance.delete("/admins/logout");

    return data;
  },
};

export default loginApi;
