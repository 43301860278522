import React, { useContext, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Menu, MenuItem, Stack, Tab, Tabs } from "@mui/material";
import Logo from "./Logo";
import Avatar from "../Common/Avatar";
import getAllowedLocations from "../../constants/getAllowedLocations";
import { AppContext } from "../../Context/Context";

const LinkTab = (props) => {
  return <Tab component={NavLink} {...props} />;
};

const NavTabs = ({ activeTab, locations }) => {
  return (
    <Stack
      sx={{
        ml: "40px",
        display: { xs: "none", md: "block" },
      }}
    >
      <Tabs value={activeTab}>
        {locations.map((location) => (
          <LinkTab key={location} label={location.substring(1)} to={location} />
        ))}
      </Tabs>
    </Stack>
  );
};

const Navigation = () => {
  const { admin, logout } = useContext(AppContext);

  const location = useLocation();

  const locations = useMemo(() => {
    if (!admin) return [];
    return getAllowedLocations(admin?.roles);
  }, [admin]);

  const path = useMemo(() => location.pathname, [location.pathname]);
  const activeTab = useMemo(
    () => locations.findIndex((el) => el === path),
    [path]
  );

  const activePage =
    location.pathname.substring(1).length < 1
      ? "choose"
      : location.pathname.substring(1);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const menuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const menuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="20px"
      sx={{
        position: "fixed",
        top: "0",
        left: "0",
        height: "75px",
        width: "100%",
        backgroundColor: "black",
        padding: { xs: "10px", md: "10px 30px" },
        zIndex: "1",
      }}
    >
      <Logo />
      <NavTabs activeTab={activeTab} locations={locations} />
      <Stack sx={{ display: { xs: "block", md: "none" } }}>
        <Button onClick={menuOpen}>{activePage}</Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={menuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {locations.map((location) => (
            <MenuItem key={location} onClick={menuClose}>
              <NavLink
                style={{
                  textDecoration: "none",
                  color: "#CBFB45",
                  textTransform: "capitalize",
                }}
                to={location}
              >
                {location.substring(1)}
              </NavLink>
            </MenuItem>
          ))}
        </Menu>
      </Stack>
      <Avatar name={admin?.name ?? "D"} logout={logout} />
    </Stack>
  );
};

export default Navigation;
