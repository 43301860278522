import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import modifiers from "../../constants/modifiers";

const CampaignModal = ({
  modifyParams,
  setModifyParams,
  isOpen,
  onClose,
  onSubmit,
  defaultState,
  title = "Edit Campaign",
}) => {
  const [state, setState] = useState(null);
  const [bodyParams, setBodyParams] = useState(false);

  useEffect(() => {
    setState({ ...defaultState });
  }, [defaultState]);

  useEffect(() => {
    if (state?.requestMethod === "POST") {
      setBodyParams(true);
    } else if (state?.requestMethod === "PUT") {
      setBodyParams(true);
    } else {
      setBodyParams(false);
    }
  }, [state]);

  const valid = useMemo(() => {
    if (!state) return false;

    return (
      Boolean(state.name) &&
      Boolean(state.requestMethod) &&
      Boolean(state.requestUrl)
    );
  }, [state]);

  const handleChange = (prop, value) => {
    setState((prev) => ({ ...prev, [prop]: value }));
  };
  const addNewModifyParams = (param = "", modifier = "") => {
    setModifyParams([
      ...modifyParams,
      { param, modifier, id: Math.floor(Math.random() * 1000) },
    ]);
  };

  const deleteModifyParams = (id) => {
    setModifyParams(
      modifyParams.filter((modifyParams) => modifyParams.id !== id)
    );
  };
  const handleChangeModifyParamsModifier = (e, id) => {
    const { value } = e.target;
    let newArr = [...modifyParams];
    newArr[id] = { ...newArr[id], modifier: value };
    setModifyParams(newArr);
  };
  const handleChangeModifyParamsParam = (e, id) => {
    const { value } = e.target;
    let newArr = [...modifyParams];
    newArr[id] = { ...newArr[id], param: value };
    setModifyParams(newArr);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Stack
        sx={{
          position: "absolute",
          top: "10%",
          left: "50%",
          transform: "translate(-50%)",
          backgroundColor: "grey.900",
          padding: { xs: "0 20px 0", md: "0 10px 0 " },
          borderRadius: "8px",
          width: { xs: "96%", md: "50%" },
          maxHeight: "80%",
          overflowY: "scroll",
        }}
      >
        <Stack
          sx={{
            position: "sticky",
            top: "0",
            width: "100%",
            backgroundColor: "grey.900",
            zIndex: "1",
            padding: "10px 0",
          }}
        >
          <Typography sx={{ padding: "10px 0" }}>{title}</Typography>
          <Divider />
        </Stack>

        <Grid
          container
          alignItems="center"
          direction={{ xs: "column", sm: "row" }}
          sx={{
            padding: "5px",
            rowGap: "10px",
            mt: "20px",
          }}
        >
          <Grid xs={12} sm={2}>
            <Typography sx={{ fontWeight: "600", fontSize: "15px" }}>
              Name
            </Typography>
          </Grid>
          <Grid xs={12} sm={10}>
            <TextField
              sx={{
                width: "100%",
              }}
              inputProps={{
                sx: {
                  paddingY: "10px",
                },
              }}
              value={state?.name}
              onChange={(e) => {
                handleChange("name", e.target.value);
              }}
              placeholder="Name"
            />
          </Grid>

          <Grid
            xs={12}
            sm={2}
            sx={{
              mt: "10px",
            }}
          >
            <Typography sx={{ fontWeight: "600", fontSize: "15px" }}>
              Method
            </Typography>
          </Grid>

          <Grid xs={12} sm={10}>
            <Select
              fullWidth
              inputProps={{
                sx: {
                  paddingY: "10px",
                },
              }}
              defaultValue={defaultState?.requestMethod}
              value={state?.requestMethod}
              onChange={(e) => {
                handleChange("requestMethod", e.target.value);
              }}
            >
              <MenuItem value={"GET"}>GET</MenuItem>
              <MenuItem value={"POST"}>POST</MenuItem>
              <MenuItem value={"PUT"}>PUT</MenuItem>
            </Select>
          </Grid>
          {bodyParams && (
            <>
              <Grid xs={12} sm={2} sx={{ mt: "10px" }}>
                <Typography sx={{ fontWeight: "600", fontSize: "15px" }}>
                  Request Body
                </Typography>
              </Grid>
              <Grid xs={12} sm={10}>
                <TextField
                  multiline
                  rows={2}
                  maxRows={4}
                  sx={{
                    width: "100%",
                  }}
                  type="text"
                  value={state?.requestBody}
                  onChange={(e) => {
                    handleChange("requestBody", e.target.value);
                  }}
                  placeholder="Request Body"
                />
              </Grid>
            </>
          )}
          <Grid xs={12} sm={2} sx={{ mt: "10px" }}>
            <Typography sx={{ fontWeight: "600", fontSize: "15px" }}>
              Request delay (minutes)
            </Typography>
          </Grid>

          <Grid xs={12} sm={10}>
            <TextField
              sx={{
                width: "100%",
              }}
              type="number"
              inputProps={{
                sx: {
                  paddingY: "10px",
                },
              }}
              value={state?.requestDelay}
              onChange={(e) => {
                handleChange("requestDelay", e.target.value);
              }}
              placeholder="Request delay (minutes)"
            />
          </Grid>

          <Grid xs={12} sm={2}>
            <Typography sx={{ fontWeight: "600", fontSize: "15px" }}>
              request Url
            </Typography>
          </Grid>

          <Grid xs={12} sm={10}>
            <TextField
              sx={{
                width: "100%",
              }}
              inputProps={{
                sx: {
                  paddingY: "10px",
                },
              }}
              value={state?.requestUrl}
              onChange={(e) => {
                handleChange("requestUrl", e.target.value);
              }}
              placeholder="request Url"
            />
          </Grid>
          <Grid xs={12} sm={2}>
            <Typography sx={{ fontWeight: "600", fontSize: "15px" }}>
              Request Config
            </Typography>
          </Grid>
          <Grid xs={12} sm={10}>
            <TextField
              sx={{
                width: "100%",
              }}
              inputProps={{
                sx: {
                  paddingY: "10px",
                },
              }}
              value={state?.requestConfig}
              onChange={(e) => {
                handleChange("requestConfig", e.target.value);
              }}
              placeholder="request Config"
            />
          </Grid>

          <Grid
            xs={12}
            sm={2}
            sx={{
              marginBottom: "auto",
              paddingTop: "10px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "15px",
                marginRight: "5px",
              }}
            >
              Modify Params
            </Typography>
          </Grid>

          <Grid xs={12} sm={10}>
            <Stack direction={"row"}>
              <Button
                fullWidth
                color="secondary"
                onClick={() => {
                  addNewModifyParams(
                    modifiers.uaPhone.param,
                    modifiers.uaPhone.modifier
                  );
                }}
              >
                {modifiers.uaPhone.name}
              </Button>
              <Button
                fullWidth
                color="secondary"
                onClick={() => {
                  addNewModifyParams(
                    modifiers.offers.param,
                    modifiers.offers.modifier
                  );
                }}
              >
                {modifiers.offers.name}
              </Button>
            </Stack>
            <Stack>
              {modifyParams.map((item, id) => (
                <Stack gap="10px" key={id}>
                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    inputProps={{
                      sx: {
                        paddingY: "10px",
                      },
                    }}
                    value={item?.param}
                    onChange={(e) => handleChangeModifyParamsParam(e, id)}
                    required
                    placeholder="Params"
                  />

                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    value={item?.modifier}
                    onChange={(e) => handleChangeModifyParamsModifier(e, id)}
                    placeholder="modifier"
                    required
                    multiline
                  />
                  <Button
                    fullWidth
                    style={{ margin: "25px auto" }}
                    onClick={() => deleteModifyParams(item.id)}
                    variant="outlined"
                    color="error"
                  >
                    delete modifier
                  </Button>
                </Stack>
              ))}
            </Stack>
          </Grid>

          <Grid xs={12} sm={2}></Grid>
          <Grid xs={12} sm={10}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={() => {
                addNewModifyParams();
              }}
            >
              add modify params
            </Button>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            mt: "20px",
            position: "sticky",
            bottom: "0",
            width: "100%",
            backgroundColor: "grey.900",
            zIndex: "1",
            padding: "10px 0",
          }}
        >
          <Button sx={{ ml: "auto" }} color="warning" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!valid}
            sx={{
              ml: "10px",
            }}
            variant="outlined"
            color="success"
            onClick={() => {
              onSubmit(state);
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default CampaignModal;
