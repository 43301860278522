import React, { useContext } from "react";
import { AppContext } from "./Context/Context";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import AppLoading from "./Components/Common/AppLoading";
import RoutesContainer from "./Routes";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#CBFB45",
      transparent40: "rgba(203,251,69, 0.4)",
    },
    bg: {
      main: "#161516",
      1: "#161516",
      2: "#211F21",
      3: "#353235",
      4: "#4D4A4D",
    },
    border: {
      main: "#3D3D3D",
    },
    secondary: {
      main: "#0495FF",
    },
    dodgerBlue: {
      main: "#0495FF",
    },
    winterSky: {
      main: "#FF1F6E",
    },
    purple: {
      main: "#8F1FFF",
    },
  },
});

const WithTheme = ({ children }) => (
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

const App = () => {
  const { appLoading, notAuthorized } = useContext(AppContext);
  if (appLoading)
    return (
      <WithTheme>
        <AppLoading />
      </WithTheme>
    );

  if (notAuthorized) return null;

  return (
    <WithTheme>
      <RoutesContainer />
    </WithTheme>
  );
};

export default App;
