import axios from "./axios";

export default {
  async createCampaign(newCampaign) {
    const { data } = await axios.post("/campaigns/new", newCampaign);

    return data;
  },
  async getAllCampaigns() {
    const { data } = await axios.get("/campaigns/all");

    return data;
  },

  async editCampaign(id, dataToUpdate) {
    const { data } = await axios.patch(`/campaigns/edit/${id}`, {
      data: dataToUpdate,
    });

    return data;
  },
  async deleteCampaign(id) {
    const { data } = await axios.delete(`/campaigns/${id}`);

    return data;
  },
};
