import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

const CampaignCard = ({
  name,
  requestMethod,
  requestBody,
  requestDelay,
  requestUrl,
  requestConfig,
  modifyParams,
  campaignId,
  onEdit,
  onCopy,
  onDelete,
}) => {
  const [modify, setModify] = useState();
  const [copiedAlertOpen, setCopiedAlertOpen] = useState(false);

  useEffect(() => {
    setModify(JSON.parse(modifyParams));
  }, [modifyParams]);

  const onCopyToClipBoard = () => {
    navigator.clipboard
      .writeText(`https://leads.axioma.monster/ads/new/${campaignId}`)
      .then(() => setCopiedAlertOpen(true));
  };
  return (
    <Card
      variant="outlined"
      sx={{
        padding: "10px",
        backgroundColor: "grey.900",
      }}
    >
      <Stack>
        <Stack
          direction={{ sm: "column", md: "row" }}
          alignItems={{ sm: "normal", md: "center" }}
          justifyContent={{ sm: "center", md: "space-between" }}
          gap="10px"
        >
          <Stack
            sx={{ height: "100%" }}
            direction="row"
            alignItems="center"
            gap="10px"
          >
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              {name}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              cursor: "pointer",
              ":hover": {
                ".copyIcon": {
                  opacity: 1,
                },
              },
            }}
            onClick={onCopyToClipBoard}
          >
            <Typography
              sx={{
                fontSize: "15px",
              }}
              color="primary"
            >
              {`https://leads.axioma.monster/ads/new/${campaignId}`}
            </Typography>
            <ContentCopyRoundedIcon
              className="copyIcon"
              color="primary"
              sx={{
                fontSize: "24px",
                ml: "5px",
                borderRadius: "50%",
                padding: "3px",
                transition: ".3s all ease",
                opacity: 0,
                ":hover": {
                  backgroundColor: "grey.700",
                },
              }}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Button color="secondary" onClick={onCopy}>
              Copy
            </Button>
            <Button color="secondary" onClick={onEdit}>
              Edit
            </Button>
            <Button color="error" onClick={onDelete}>
              Delete
            </Button>
          </Stack>
        </Stack>

        <Divider />

        <Grid
          container
          direction={{ xs: "column", md: "row" }}
          sx={{
            padding: "5px",
            rowGap: "15px",
          }}
        >
          <Grid xs={12} sm={2}>
            <Typography
              sx={{ fontWeight: "600", fontSize: "15px", mr: "15px" }}
            >
              Method
            </Typography>
          </Grid>

          <Grid xs={12} sm={9}>
            <Typography
              sx={{
                fontSize: "14px",
              }}
            >
              {requestMethod}
            </Typography>
          </Grid>
          {requestMethod === "POST" && (
            <>
              <Divider />

              <Grid xs={12} sm={2}>
                <Typography
                  sx={{ fontWeight: "600", fontSize: "15px", mr: "15px" }}
                >
                  Request body
                </Typography>
              </Grid>

              <Grid xs={12} sm={9}>
                <Typography
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {requestBody}
                </Typography>
              </Grid>
            </>
          )}
          <Divider />

          <Grid xs={12} sm={2}>
            <Typography
              sx={{ fontWeight: "600", fontSize: "15px", mr: "15px" }}
            >
              URL
            </Typography>
          </Grid>

          <Grid xs={12} sm={9}>
            <Typography
              sx={{
                fontSize: "14px",
              }}
            >
              {requestUrl}
            </Typography>
          </Grid>
          {requestConfig !== "{}" && (
            <>
              <Divider />

              <Grid xs={12} sm={2}>
                <Typography
                  sx={{ fontWeight: "600", fontSize: "15px", mr: "15px" }}
                >
                  Request Config
                </Typography>
              </Grid>

              <Grid xs={12} sm={9}>
                <Typography
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {requestConfig}
                </Typography>
              </Grid>
            </>
          )}
          {requestDelay > 0 && (
            <>
              <Divider />

              <Grid xs={12} sm={2}>
                <Typography
                  sx={{ fontWeight: "600", fontSize: "15px", mr: "15px" }}
                >
                  request Delay (minutes)
                </Typography>
              </Grid>

              <Grid xs={12} sm={9}>
                <Typography
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {requestDelay}
                </Typography>
              </Grid>
            </>
          )}
          <Divider />
          {modify !== "" &&
            modify?.map((item, index) => (
              <React.Fragment key={index}>
                <Grid xs={12} sm={2}>
                  <Typography
                    sx={{ fontWeight: "600", fontSize: "15px", mr: "15px" }}
                  >
                    Params
                  </Typography>
                </Grid>
                <Grid xs={12} sm={9}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    {item.param}
                  </Typography>
                </Grid>
                <Divider />
                <Grid xs={12} sm={2}>
                  <Typography
                    sx={{ fontWeight: "600", fontSize: "15px", mr: "15px" }}
                  >
                    Modifier
                  </Typography>
                </Grid>
                <Grid xs={12} sm={9}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    {item.modifier}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}

          <Divider />

          <Grid xs={12} sm={2}>
            <Typography
              sx={{ fontWeight: "600", fontSize: "15px", mr: "15px" }}
            >
              Link
            </Typography>
          </Grid>

          <Grid xs={12} sm={9}>
            <Typography
              sx={{
                fontSize: "14px",
              }}
            >
              {`https://leads.axioma.monster/ads/new/${campaignId}`}
            </Typography>
          </Grid>

          <Snackbar
            open={copiedAlertOpen}
            autoHideDuration={6000}
            severity="success"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }

              setCopiedAlertOpen(false);
            }}
          >
            <Alert
              onClose={(event, reason) => {
                if (reason === "clickaway") {
                  return;
                }

                setCopiedAlertOpen(false);
              }}
              severity="success"
              sx={{ width: "100%" }}
            >
              Copied!
            </Alert>
          </Snackbar>
        </Grid>
      </Stack>
    </Card>
  );
};

export default CampaignCard;
