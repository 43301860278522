import React from "react";
import { Stack, Typography } from "@mui/material";
import logoImage from "../../assets/images/logo.png";

const Logo = () => {
  return (
    <Stack>
      <img
        src={logoImage}
        alt=""
        style={{
          maxWidth: "200px",
          width: "100%",
          minWidth: "105px",
        }}
      />
      <Typography
        sx={{
          textAlign: "end",
        }}
      >
        Leads
      </Typography>
    </Stack>
  );
};

export default Logo;
